import React from "react";
import styled from "styled-components";
import Check from "../Assets/icon_check.svg";
const StyledCardCheck = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  padding: 16px;
  margin: 20px;
  min-height: 150px;
  width: 100%;
  max-width: 480px;
  height: 150px;
  h1 {
    color: var(--strong-blue);
    font-size: 16px;
    margin-top: 25px;
  }
  @media (min-width: 1200px) {
    height: 280px;
    h1 {
      font-size: 25px;
      font-weight: 500;
    }
  }
  @media (min-width: 1400px) {
    width: 480px;
    h1 {
      font-size: 30px;
    }
  }
`;
export default function CardCheck(props) {
    const {titleCheck} = props;
  return (
    <StyledCardCheck>
      <img src={Check} alt="icon_check" />
      <h1>{titleCheck}</h1>
    </StyledCardCheck>
  );
}
