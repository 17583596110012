import React from "react";
import styled from "styled-components";

const StyledHeader = styled.div`
  padding: 70px 16px;
  max-width: 1200px;
  margin: 0 auto;
  h1 {
    color: var(--strong-blue);
    font-family: "lato_bold";
    font-size: 24px;
    text-align: center;
  }
  h2 {
    font-family: "lato_regular";
    font-size: 20px;
    color: var(--gray-strong);
    text-align: center;
    margin-top: 10px;
    font-weight: 200;
  }
  @media (min-width: 1200px) {
    padding: 30px 16px 70px;
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 22px;

      max-width: 80%;
      text-align: center;
      margin: auto;
      margin-top: 30px;
    }
  }
`;
export default function HeaderBlueGray(props) {
  const { title, subtitle } = props;
  return (
    <StyledHeader>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </StyledHeader>
  );
}
