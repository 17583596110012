import React from "react";
import styled from "styled-components";
import CardValores from "../utils/CardValores";
import Back from "../Assets/back_valores.webp";
const StyledValores = styled.div`
  background-color: var(--white);
  position: relative;
  height: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
    height: 25%;
  }
  .top-center {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0%);
    h3 {
      font-family: "lato_bold";
      font-size: 22px;
      color: var(--strong-blue);
      text-align: center;
    }
    .container_cards {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  @media (min-width: 1200px) {
    height: 500px;
    h3 {
      font-size: 30px !important;
      margin-bottom: 100px;
      padding-bottom: 0px;
    }
    .test{
        margin-bottom: 100px;
        height: 100%;
    }
    .top-center {
        top: 0%;
      .container_cards {
        display: flex;
        flex-direction: row;
      }
    }
  }
`;
export default function Valores() {
  return (
    <StyledValores>
      <img className="test" src={Back} alt="image_back" />
      <div className="top-center">
        <h3>Valores</h3>
        <div className="container_cards">
          {cardsArray.map((e, idx) => {
            return <CardValores key={idx} icon={e.image} title={e.title} />;
          })}
        </div>
      </div>
    </StyledValores>
  );
}

const cardsArray = [
  {
    image: require("../Assets/icon_medica.svg").default,
    title: "Excelencia médica",
  },
  {
    image: require("../Assets/icon_graduate.svg").default,
    title: "Enseñanza",
  },
  {
    image: require("../Assets/icon_cross.svg").default,
    title: "Profesionalismo",
  },
  {
    image: require("../Assets/icon_tech.svg").default,
    title: "Tecnología",
  },
];
