import React, {useState, useEffect} from "react";
import Layout from "../Components/Layout/index";
import Portada from "../utils/Portada";
import Mobile from "../Assets/portada_nosotros.webp";
import Desktop from "../Assets/portada_nosotros_desktop.webp";
import HeaderBlueGray from "../utils/HeaderBlueGray";
import BoxContainer from "../utils/BoxContainer";
import styled from "styled-components";
import CardCheck from "../utils/CardCheck";
import Valores from "../Components/Valores";
const title =
  "Somos el Instituto Mexicano de Otología y Neurotología S.C. (IMON).";
const subtitle =
  "Una institución fundada en 1993 y especializada en ofrecer recursos de vanguardia para el diagnóstico y tratamiento de las enfermedades del oído y estructuras conexas que afectan a la salud auditiva.";

const title2 = "¿Por qué elegir IMON?";
const subtitle2 =
  "En el año 1988, fuimos los pioneros del programa de implante coclear en México y tenemos al mejor equipo multidisciplinario, la tecnología y la experiencia para diagnosticar y tratar problemas de audición.";

const StyledNosotros = styled.div`
  h3 {
    font-family: "lato_bold";
    font-size: 24px;
    color: var(--strong-blue);
    text-align: center;
    margin-top: 10px;
    padding-bottom: 56px;
  }
  .container_cards_check {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 30px;
      padding-bottom: 0px;
    }
    .container_cards_check {
      display: flex;
      flex-direction: row;
    }
  }
`;
const Nosotros = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted ?  (
    <StyledNosotros>
      <Layout>
        <Portada imageMobile={Mobile} imageDesktop={Desktop} title="Nosotros" />
        <div style={{marginTop: "80px"}}></div>
        <HeaderBlueGray title={title} subtitle={subtitle}/>
        <BoxContainer>
          <HeaderBlueGray title={title2} subtitle={subtitle2} />
          <h3>Diferenciadores</h3>
          <div className="container_cards_check">
            {titlesArray.map((e, idx) => {
              return <CardCheck key={idx} titleCheck={e.title} />;
            })}
          </div>
        </BoxContainer>
        <Valores />
      </Layout>
    </StyledNosotros>
  ) :  null
}

const titlesArray = [
  {
    title: "Atendemos a todo tipo de pacientes",
  },
  {
    title:
      "Brindamos tratamiento personalizado a las características de cada caso.",
  },
  {
    title:
      "Informamos a las personas sobre los peligros del oído para prevenir pérdidas auditivas.",
  },
];

export default Nosotros