import React from "react";
import styled from "styled-components";
const StyledValores = styled.div`
  width: 200px;
  height: 148px;
  background-color: var(--white);
  border: 1.5px solid var(--strong-blue);
  box-shadow: 0px 8px 28px -6px rgba(155, 171, 134, 0.3),
    0px 18px 88px -4px rgba(115, 124, 103, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  &:hover {
      transition: all 0.2 ease;
      transform: scale(1.1);
    }
  img {
    width: 40px;
  }
  h1 {
    font-family: "lato_bold";
    font-size: 20px;
    color: var(--strong-blue);
  }
  @media (min-width: 1200px) {
    &:hover {
      transition: all 0.2 ease;
      transform: scale(1.1);
      margin-left: 20px;
      margin-right: 10px;
    }
    width: 270px;
    height: 243px;
    img {
      width: 80px;
    }
    h1 {
      font-size: 22px;
    }
  }
  @media (min-width: 1400px) {
    width: 300px;
  
  }
`;
export default function CardValores(props) {
    const {icon, title} = props;
  return (
    <StyledValores>
      <img src={icon} alt="icon" />
      <h1>{title}</h1>
    </StyledValores>
  );
}
